.kerst-thema {
  background-color: #800000;

  .navbar {
    height: 1.8em;
    min-height: 1.8em;

    @include from($tablet) {
      height: 3.5em;
      min-height: 3.5em;
    }

    .navbar-brand,
    .container {
      height: 1.8em;
      min-height: 1.8em;

      @include from($tablet) {
        height: 3.5em;
        min-height: 3.5em;
      }
    }

    .navbar-burger {
      height: 1.8em;
      min-height: 1.8em;

      @include from($tablet) {
        height: 3.5em;
        min-height: 3.5em;
      }
    }
  }

  .countdown {
    color: #fff;

    .card-content {
      margin-top: 1em;
      padding: .5rem;

      @include from($tablet) {
        margin-top: 7em;
        padding: 1.5rem;
      }

      h1 {
        font-family: 'Caveat Brush', cursive;
        color: #fff;
        font-size: 3em;
        text-shadow: 2px 2px #000;

        @include from($tablet) {
          font-size: 5.5em;
        }
      }

      h4 {
        font-family: 'Amatic SC', cursive;
        color: #fff;
        font-size: 1.6em;
        line-height: 1.3;
        text-shadow: 1px 1px #000;
        text-align: center;

        @include from($tablet) {
          font-size: 2.2em;
        }
      }

      .countdown-kerst {
        margin-top: 2em;

        @include from($tablet) {
          margin-top: 4em;
        }

        .circles {
          display: inline-block;
          margin-right: .9em;

          @include from($tablet) {
            margin-right: 1.6em;
          }

          &:last-child {
            margin-right: 0;
          }

          .circle {
            display: block;
            border: solid 2px #000;
            border-radius: 50%;
            width: 2.4em;
            height: 2.4em;
            padding: 0.35em 0.27em;
            text-align: center;
            font-size: 1.4em;
            font-weight: bold;
            background: #5b0000;
            background: linear-gradient(#5b0000,#751818);

            @include from($tablet) {
              border: solid 3px #000;
              width: 2.5em;
              height: 2.5em;
              padding: 0.45em 0.3em;
              font-size: 3em;
            }
          }

          .text {
            display: block;
            color: #ccc;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.5em;
            margin-top: 1em;

            @include from($tablet) {
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }

  main {
    min-height: calc(100vh - 270px);
  }

  footer {
    padding: 0;
    margin: 0;
    background: none;
    text-align: center;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    @include from($tablet) {
      position: fixed;
    }

    .img-container {
      overflow: hidden;

      img {
        margin: 0 0 0 -960px;
        padding: 0;
        display: block;
        height: 270px;
        width: auto;
        max-width: initial;

        @include from($tablet) {
          margin: 0;
          height: auto;
          width: auto;
          max-width: 100%;
        }
      }
    }

    .content {
      background-color: #F7F7F7;
      font-size: 0.6em;
      padding: 0 0 0.5em 0;
    }
  }
}
